import config from './config';

/**
 * @typedef {'getReasonCodesV2' | 'postReturnCaptures' | 'getGiftCardBalance' | 'getOrderDetail' |
 * 'postCancelLineItem' | 'getOrderSummaryV2' | 'assetSearchV2' | 'postAddressValidator' |
 * 'storeViews' | 'postReturnLabelsDispatch' | 'postReturnLocation' | 'getOrderNotificationsOMA' |
 * 'getSearchDetailOMA' | 'postEmailResendOMA' | 'postReadIdentityUser' | 'postReturnInspections' |
 * 'addLineItemDiscount' | 'addShippingDiscount' | 'postCustomerProfile'
 * | 'getReturnedShippingCharges' | 'getRetailStorePaymentDetails'
 * | 'postAddNewCreditCard'} OperationName
 */

/**
 * Forms the mockurl based on operation
 * @param {OperationName} operationName - operation name passed to graphql call
 * @param {object} variables - request body to grapghql call
 */
const getMockUrl = (operationName, variables) => {
  const orderNumber = variables?.orderNumber;
  switch (operationName) {
    // Need to send type as reason code response is based on reason code type
    case 'getReasonCodesV2': {
      const type = variables.type;
      return `${config.mockUrl}?operationName=${operationName}&type=${type}`;
    }
    case 'postReturnCaptures': {
      const orderNumber = variables?.input?.orderNumber;
      return `${config.mockUrl}?operationName=${operationName}&orderNumber=${orderNumber}`;
    }
    case 'getGiftCardBalance':
    case 'getOrderDetail':
    case 'postCustomerProfile':
    case 'postCancelLineItem': {
      return `${config.mockUrl}/${orderNumber}?operationName=${operationName}`;
    }
    case 'getOrderSummaryV2': {
      // Determines the mock url based on the filter criteria
      if (
        variables?.filters.filter(
          (variable) =>
            variable.key === 'orderNumber' || variable.key === `billTo.contactInformation.email`
        ).length === 0
      ) {
        return `${config.mockUrl}?operationName=${operationName}_advancedSearch`;
      }
      return `${config.mockUrl}?operationName=${operationName}`;
    }
    case 'postAddNewCreditCard': {
      const orderNumber = variables?.input?.orderNumber;
      return `${config.mockUrl}?operationName=${operationName}&orderNumber=${orderNumber}`;
    }
    // most operations fall under this category
    default:
      return `${config.mockUrl}?operationName=${operationName}`;
  }
};
export default getMockUrl;
