import React, { useState } from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

/**
 * This is a custom hook to to provide access to the Okta JWT stored in local storage.
 */
export default function useAuth() {
  const [expiresAt, setExpiresAt] = useState(0);

  /**
   * This function grabs the okta token its expiration time from local storage.
   * returns oktaToken and set oktaExpiration to local state
   */
  const getOktaFromLocalStorage = () => {
    let oktaToken;
    let jsonToken;
    /*
      We need to pay attention to a specific error that can occur when trying to get
      the stored okta token via localStorage, which is often due to the user not having 
      3rd party cookies enabled.
    */
    try {
      jsonToken = window.localStorage.getItem('okta-token-storage');
      console.log('token from local storage is ', jsonToken);
    } catch (err) {
      // If the error is the 3rd-party cookies issue, then return an error component for the user
      if (err.message.includes("Failed to read the 'localStorage' property from 'Window'")) {
        return (
          <Box style={{ margin: '150px', marginTop: '50px' }}>
            <img
              alt='omobo logo'
              src={process.env.PUBLIC_URL + '/assets/img/omobo.png'}
              style={{ maxWidth: '100%' }}
            />
            <Typography
              color='error'
              component='h2'
              variant='h2'
              style={{
                fontFamily: 'Helvetica Neue, Helvetica, Arial, sans-serif',
                textAlign: 'center',
              }}>
              Please enable 3rd party cookies to access the application.
            </Typography>
          </Box>
        );
      }
    }

    try {
      // grab the token from local storage and try to parse it
      const tokenDetails = jsonToken && JSON.parse(jsonToken);
      setExpiresAt(tokenDetails?.accessToken?.expiresAt);
      oktaToken = tokenDetails?.accessToken?.accessToken;
    } finally {
      console.log('token being returned is ', oktaToken);
      return oktaToken;
    }
  };

  const [oktaToken] = useState(() => getOktaFromLocalStorage());

  /**
   * This function checks if the okta token is expired on not.
   * returns true if expired otherwise returns false
   */
  const isOktaExpired = () => {
    console.log('expiresAt', expiresAt);
    if (oktaToken && expiresAt) {
      if (parseInt(expiresAt, 10) < parseInt(Math.trunc(Date.now()) / 1000, 10)) {
        return true;
      }
    }
    return false;
  };

  /**
   * This function removes the invalid and expired oktaToken from local storage.
   */
  const validateOktaToken = () => {
    if (!oktaToken || isOktaExpired()) {
      console.log('removing the token');
      window.localStorage.removeItem('okta-token-storage');
    }
  };

  return {
    oktaToken,
    validateOktaToken,
  };
}
